<template>
  <div class="top-card mb-4">
    <div
      is="CustomCard"
      :title="$t('Pages.Auth.account_information')"
      icon="settings"
    >
      <v-form
        id="account-form"
        ref="form"
        v-model="valid"
        label-width="100px"
        class="no-required-mark"
        lazy-validation
        @submit.prevent="savePhoneNumber()"
      >
        <v-text-field
          id="phone-input"
          v-model="phone"
          :maxlength="13"
          color="accent"
          type="tel"
          :rules="[importedRules.phone]"
          outlined
          rounded
          :label="$t('Common.phone_number')"
          @input="saveEnabled = false"
        />
        <div class="button-container">
          <v-btn
            id="save-btn-account"
            :loading="loading"
            :disabled="saveEnabled || !valid || loading"
            class="cta-btn"
            rounded
            type="submit"
            v-text="$t('Common.save')"
          />
        </div>
      </v-form>
    </div>
  </div>
</template>

<script>
import CustomCard from '@/common/CustomCard.vue'

import { rules } from '@/config/validation-rules.js'
import { EDIT_USER } from '@/store/actions.type'

export default {
  name: 'SettingsInformation',
  components: {
    CustomCard
  },
  props: {
    createAlert: {
      type: Function,
      default: function () {
      }
    }
  },
  data () {
    return {
      saveEnabled: true,
      loading: false,
      valid: false,
      phone: this.$store.state.user.phone
    }
  },
  computed: {
    importedRules: () => rules
  },
  methods: {
    savePhoneNumber () {
      this.loading = true
      if (this.$refs.form.validate()) {
        this.$emit('setSave')
        const user = {
          phone: this.phone
        }
        this.$store.dispatch(EDIT_USER, user).then(
          () => {
            this.loading = false
            this.saveEnabled = true
          }, err => {
            this.loading = false
            console.error(err)
            this.$root.$emit('errorMessage', err.response)
          }
        )
      }
    }
  }
}
</script>
