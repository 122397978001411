<template>
  <div>
    <!-- CHANGE EMAIL CARD -->
    <div class="top-card mb-4">
      <div
        is="CustomCard"
        :title="$t('Pages.Settings.change_email')"
        icon="contact_mail"
      >
        <v-form
          ref="emailForm"
          v-model="emailValid"
          @submit.prevent="changeEmail()"
        >
          <p v-text="$t('Pages.Settings.a_confirmation')" />
          <v-text-field
            id="new-email-input"
            v-model="email"
            :rules="[importedRules.email, importedRules.required]"
            class="mt-4"
            type="email"
            color="accent"
            :label="$t('Pages.Settings.email_address')"
            auto-complete="off"
            outlined
            rounded
            @input="isEmailChanged = true"
          />
          <div class="button-container">
            <v-btn
              id="change-email-confirm-btn"
              :loading="loadingEmail"
              :disabled="!isEmailChanged || !emailValid || loadingEmail"
              class="cta-btn"
              depressed
              rounded
              type="submit"
              v-text="$t('Common.save')"
            />
          </div>
        </v-form>
      </div>
    </div>
    <!-- ACCOUNT DELETION CARD -->
    <div class="top-card mb-4">
      <div
        is="CustomCard"
        :title="$t('Pages.Settings.account_deletion')"
        icon="delete"
      >
        <v-form
          id="manage-account-form"
          ref="form"
          label-width="100px"
          class="no-required-mark"
        >
          <p v-text="$t('Pages.Settings.permanently_delete')" />
          <div class="button-container">
            <v-btn
              id="delete-btn-account"
              :loading="loading"
              class="error"
              depressed
              rounded
              @click="onOpenDeleteDialog()"
              v-text="$t('Pages.Settings.delete_account')"
            />
          </div>
        </v-form>
      </div>
    </div>
    <v-dialog
      v-model="removeDialog"
      max-width="500"
      class="ma-0"
    >
      <div
        is="CustomDialog"
        id="bankID-dialog"
        title-icon="block"
        :title="$t('Pages.Settings.confirm_account')"
      >
        <v-form
          slot="form"
          @submit.prevent="deleteAccount(confirmInput)"
        >
          <p>
            {{ $t('Pages.Settings.we_are_sorry') }}
          </p>
          <p v-html="$t('Pages.Settings.to_confirm')" />
          <v-text-field
            id="pass-input"
            v-model="confirmInput"
            color="accent"
            type="text"
            outlined
            rounded
            auto-complete="off"
            required
            autofocus
          />
          <div class="button-container">
            <v-btn
              id="delete-account-confirm-btn"
              :disabled="confirmInput !== 'delete'"
              class="error"
              depressed
              rounded
              type="submit"
              v-text="$t('Common.delete')"
            />
          </div>
        </v-form>
      </div>
    </v-dialog>

    <v-dialog
      v-model="successDialog"
      max-width="500"
    >
      <div
        is="CustomDialog"
        title-icon="done"
        :title="$t('Pages.Settings.account_deleted')"
      >
        <v-progress-linear
          :indeterminate="true"
          color="warning"
          class="top-progress"
        />
        <div slot="form">
          <h3>
            {{ $t('Pages.Auth.logging_out') }}
          </h3>
        </div>
        <div
          slot="buttons"
          class="button-container"
        />
      </div>
    </v-dialog>
  </div>
</template>

<script>
import CustomCard from '@/common/CustomCard.vue'
import CustomDialog from '@/common/CustomDialog.vue'

import { rules } from '@/config/validation-rules.js'
import { CHANGE_EMAIL, DELETE_ACCOUNT, LOGOUT } from '@/store/actions.type'

export default {
  name: 'SettingsManage',
  components: {
    CustomCard,
    CustomDialog
  },
  props: {
    createAlert: {
      type: Function,
      default: function () { }
    }
  },
  data () {
    return {
      emailValid: false,
      saveEnabled: true,
      isEmailChanged: false,
      loading: false,
      loadingEmail: false,
      confirmInput: '',
      loader: null,
      successDialog: false,
      removeDialog: false,
      email: this.$store.state.user.email,
      importedRules: rules,
      cancel: false,
      sameDeviceUrl: null,
      orderRef: null
    }
  },
  methods: {
    validate (formRef) {
      this.$refs[formRef].validate()
    },
    reset (formRef) {
      this.$refs[formRef].form.reset()
    },
    resetValidation (formRef) {
      this.$refs[formRef].resetValidation()
    },
    onOpenDeleteDialog () {
      this.removeDialog = true
    },
    changeEmail () {
      this.loadingEmail = true
      this.validate('emailForm')
      this.$emit('setSave')
      const postData = {
        newEmail: this.email
      }
      this.$store.dispatch(CHANGE_EMAIL, postData).then(() => {
        setTimeout(() => {
          this.$emit('setSave')
          this.loadingEmail = false
        }, 1000)
      }, err => {
        console.error('err', err)
        this.$emit('setSave')
        if (err.status === 0 || err.status === 500) {
          this.createAlert('Unable to change email at this time. Please try again later.', 'warning', 'error')
        } else {
          this.$root.$emit('errorMessage', err.response)
        }
        this.loadingEmail = false
      })
    },
    deleteAccount (confirmation) {
      this.$store.dispatch(DELETE_ACCOUNT, confirmation).then(() => {
        this.removeDialog = false
        setTimeout(() => {
          this.successDialog = true
        }, 1000)
        this.$store.dispatch(LOGOUT).then(() => {
          this.$router.push({ name: 'accounts-login' })
        })
      }, err => {
        this.loading = false
        if (this.cancel === true) return
        this.$root.$emit('errorMessage', err.response)
        this.password = ''
      })
    }
  }
}
</script>
