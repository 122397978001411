<template>
  <div>
    <h2
      id="account-settings"
      class="mb-4 hidden-xs-only"
      v-text="$t('Pages.Settings.account_settings')"
    />
    <SettingsInformation :create-alert="createAlert" />
    <SettingsManage :create-alert="createAlert" />
  </div>
</template>
<script>
import SettingsInformation from '@/pages/settings/components/SettingsInformation.vue'
import SettingsManage from '@/pages/settings/components/SettingsManage.vue'
export default {
  components: {
    SettingsManage,
    SettingsInformation
  },
  props: {
    createAlert: {
      type: Function,
      default: () => {}
    }
  }
}
</script>
